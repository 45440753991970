@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  border: 0;
  box-sizing: border-box !important;
  font-family: 'Poppins', sans-serif !important;
  margin: 0;
  padding: 0;
  outline: 0;
  scroll-behavior: smooth;
}

body, #root {
  height: 100%;
}

body {
  background: #f7f9fe;
  -webkit-font-smoothing: antialiased;
}

button {
  cursor: pointer;
}

button:focus {
  outline: 0px !important;
}

.makeStyles-container-1 {
  display: none !important;
}
[class*='MuiInput-underline']::before, [class*='MuiInput-underline']::after {
  border-bottom: 0 !important;
}

a.orange {
  margin-left: 4px;
  color: #f47920;
  font-family: 'Poppins', sans-serif !important;
}

.field_with_assessment_label {
  margin-bottom: 20px;
}